import React from "react";

function Banner({ height = 'calc(100vh - 65px)' }) {
  return (
    <section className="banner-section relative" style={{ height }}>
      <div className="d-flex align-items-center abs-full tint z-index-1">
        <div className="container">
          <h2 className="banner-title">A Luxury Address</h2>
          <p className="banner-tagline">
            Improving Lifestyles - Enhancing Lives
          </p>
        </div>
      </div>
      <video
        poster="/videos/Asset-41.png"
        src="/videos/banner_new_1.mp4"
        autoPlay
        muted
        loop
      />
    </section>
  );
}

export default Banner;
