import React from "react";
import ProjectCard from "./ProjectCard";
import OngoingList from "./OngoingList";

function Ongoing({ projectOnClick = null }) {
  return (
    <section className="section-pad border-bottom">
      <div className="container-xl container-fluid">
        <h3 className="section-title">Residential Ongoing Projects</h3>
        <div className="row justify-content-center">
          <OngoingList
            RenderItem={props => (
              props.slug !== "emperors-pavilion" ? <div className="col-md-6 col-xl-4 mb-3">
                <ProjectCard onClick={projectOnClick} {...props} />
              </div> : <></>
            )}
          />
        </div>
      </div>
    </section>
  );
}

export default Ongoing;
