import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

function ProjectLogos({ conClass = "section-pad border-bottom" }) {
  const data = useAllProjectLogos();

  return (
    <section className={conClass}>
      <div className="container">
        <h3 className="section-title text-center">Prestigious Projects</h3>
        <div className="row align-items-center justify-content-center">
          <div className="col-6 col-md-2 wow zoomIn justify-content-center">
            <Link to="/crown-residence/">
              <Img
                className="mb-3"
                fluid={data.image8.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/the-peak/">
              <Img
                className="mb-3"
                fluid={data.image9.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/cloud-graze/">
              <Img
                className="mb-3"
                fluid={data.image7.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/the-plutus-residence/">
              <Img
                className="mb-3"
                fluid={data.image1.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/bonaventura/">
              <Img
                className="mb-3"
                fluid={data.image2.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/promenade/">
              <Img
                className="mb-3"
                fluid={data.image11.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/royal-damera-gardens/">
              <Img
                className="mb-3"
                fluid={data.image10.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/le-grand-trianon/">
              <Img
                className="mb-3"
                fluid={data.image3.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/emperors-pavilion/">
              <Img
                className="mb-3"
                fluid={data.image4.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/sadhr-gardens/">
              <Img
                className="mb-3"
                fluid={data.image5.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/57-poes/">
              <Img
                className="mb-3"
                fluid={data.image6.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
          <div className="col-6 col-md-2 wow zoomIn">
            <Link to="/willow-square/">
              <Img
                className="mb-3"
                fluid={data.image12.childImageSharp.fluid}
                alt=""
              />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export const useAllProjectLogos = () =>
  useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "project-logos/prestigious-logo-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(
        relativePath: { eq: "project-logos/prestigious-logo-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(
        relativePath: { eq: "project-logos/prestigious-logo-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(
        relativePath: { eq: "project-logos/prestigious-logo-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(
        relativePath: { eq: "project-logos/prestigious-logo-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(
        relativePath: { eq: "project-logos/prestigious-logo-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image7: file(
        relativePath: { eq: "project-logos/prestigious-logo-7.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image8: file(
        relativePath: { eq: "project-logos/prestigious-logo-8.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image9: file(
        relativePath: { eq: "project-logos/prestigious-logo-9.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image10: file(
        relativePath: { eq: "project-logos/prestigious-logo-10.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image11: file(
        relativePath: { eq: "project-logos/prestigious-logo-11.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image12: file(
        relativePath: { eq: "project-logos/prestigious-logo-12.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

export default ProjectLogos;
